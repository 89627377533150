<template>

  <ion-card>
    <div class="loaded" v-if="finishedLoading">
      <ion-card-header>

        <ion-row>
          <ion-col size="12">
            <small>Ticket-ID: {{ inserat.raffleId }}</small><br>
          </ion-col>
          <ion-col size="3">
            <ion-icon :ios="ticketIos" :md="ticketMd" style="width: 50px; height: 50px;"></ion-icon>
          </ion-col>
          <ion-col size="9">
            <ion-card-title>{{ inserat.title }}</ion-card-title>
            <ion-card-title v-if="inserat.raffleStatus === 'waiting'" color="warning">{{ inserat.price }} {{ inserat.currency }}</ion-card-title>
            <ion-card-title v-if="inserat.raffleStatus === 'winner'" color="success">{{ inserat.price }} {{ inserat.currency }}</ion-card-title>
            <ion-card-title v-if="inserat.raffleStatus === 'closed' || inserat.raffleStatus === 'lost'" color="danger">{{ inserat.price }} {{ inserat.currency }}</ion-card-title>
          </ion-col>
        </ion-row>
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col size="6" v-if="checkRaffleEndDate(inserat.raffleEndAt)">Auslosung {{ calculateToRaffleEndDate(inserat.raffleEndAt) }}</ion-col>
            <ion-col size="6" v-if="!checkRaffleEndDate(inserat.raffleEndAt) && inserat.raffleStatus === 'waiting'">Gewinner wird aktuell ausgewählt</ion-col>
            <ion-col size="6" v-if="!checkRaffleEndDate(inserat.raffleEndAt) && inserat.raffleStatus !== 'waiting'">
              Status:
              <strong v-if="inserat.raffleStatus === 'winner'">Gewonnen</strong>
              <strong v-if="inserat.raffleStatus !== 'winner'">Verloren</strong>
            </ion-col>
            <ion-col size="12" v-if="!checkRaffleEndDate(inserat.raffleEndAt) && inserat.raffleStatus === 'winner'">
              <small v-if="payment && calculateToPaymentEndDate(payment.dueAt) > 0">Du hast noch {{ calculateToPaymentEndDate(payment.dueAt) }}</small>
              <ion-button v-if="payment && calculateToPaymentEndDate(payment.dueAt) > 0" style="width: 100%; z-index: 99;" :router-link="getPaymentRoute(payment.id)">Jetzt bezahlen</ion-button>
              <small v-if="payment && calculateToPaymentEndDate(payment.dueAt)">Du hast leider die Zeit überschritten. Ein anderer Gewinner wurde ausgewählt.</small>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </div>
    <div class="container" v-if="!finishedLoading">
      <ion-spinner name="dots"></ion-spinner>
    </div>
  </ion-card>

</template>

<script>
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow, IonSpinner, IonIcon } from '@ionic/vue';
import axios from "axios";
import moment from "moment";
import { ticketOutline, ticketSharp } from 'ionicons/icons';

export default {
  name: "inseratEntry",
  props: {
    inserat: null
  },
  data () {
    return {
      payment: null,
      finishedLoading: false,
      ticketIos: ticketOutline,
      ticketMd: ticketSharp
    }
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSpinner,
    IonIcon
  },
  methods: {
    getRoute(id) {
      return "/inserat/" + id;
    },
    calculateToPaymentEndDate(date){

      const now = moment(new Date()); //todays date
      const end = moment(date); // end date
      const duration = moment.duration(end.diff(now));
      const days = duration.asDays();
      const hours = duration.asHours();

      let daysStr = days.toFixed(0) + " Tage";
      if (days > 1){
        daysStr = days.toFixed(0) + " Tagen";
      }

      let hoursStr = hours.toFixed(0) + " Stunden";
      if (hours <= 1){
        hoursStr = hours.toFixed(0) + " Stunde";
      }

      if (days === 0){
        return hoursStr;
      }

      return daysStr;
    },
    calculateToRaffleEndDate(date){
      const now = moment(new Date()); //todays date
      const end = moment(date); // end date
      const duration = moment.duration(end.diff(now));
      const days = duration.asDays();
      const hours = duration.asHours();

      let daysStr = days.toFixed(0) + " Tage";
      if (days > 1){
        daysStr = days.toFixed(0) + " Tagen";
      }

      let hoursStr = hours.toFixed(0) + " Stunden";
      if (hours <= 1){
        hoursStr = hours.toFixed(0) + " Stunde";
      }

      if (days === 0){
        return "in " + hoursStr;
      }

      if (days === 0 && hours === 0){
        return "Jetzt";
      }

      return "in " + daysStr;
    },
    checkRaffleEndDate(date){
      return moment(date).isAfter();
    },
    getPaymentRoute(id){
      return '/check-payment/' + id;
    }
  },
  created() {
    // retrieve payment information

    const inserat = this.$props.inserat;

    if (inserat.raffleStatus === 'winner'){
      // get payment nonce
      inserat.payment = null;
      const formData = new FormData();
      formData.append("inserat", inserat.uuid);
      axios.post(process.env.VUE_APP_API_URL + '/payment-raffle-entry', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      }).then(result => {
        if (result.data.status) {
          this.payment = {
            'id': result.data.paymentData.paymentId,
            'dueAt': result.data.paymentData.dueAt,
            'dueAtCountdown': this.calculateToPaymentEndDate(result.data.paymentData.dueAt)
          };
        }
      }).finally(() => {
        this.finishedLoading = true;
      });
    } else {
      this.finishedLoading = true;
    }

  }
}
</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

</style>