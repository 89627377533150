<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Deine Tickets</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" v-if="finishedLoading">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            Deine Tickets
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ totalRaffleEntries }} Ergebnisse gefunden</ion-card-subtitle>
        </ion-card-header>
      </ion-card>

      <inseratEntry v-for="(inserat, key) in inserats" :key="key" :inserat="inserat"></inseratEntry>

    </ion-content>

    <div class="container" v-if="!finishedLoading">
      <ion-spinner name="dots"></ion-spinner>
    </div>

  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import inseratEntry from '../../components/inseratEntry.vue';
import { ticketOutline, ticketSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'ShowAll',
  data () {
    return {
      inserats: [],
      raffles: [],
      totalRaffleEntries: 0,
      finishedLoading: false,
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonSpinner,
    inseratEntry
  },
  created() {
    axios.get(process.env.VUE_APP_API_URL + '/inserat_raffle_entries?raffleUser.uuid=' + localStorage.userId + '&order[createdAt]=DESC')
        .then(response => {
          this.raffles = response.data["hydra:member"];
          this.totalRaffleEntries = response.data["hydra:totalItems"];
          let finishedLoadingEntriesCount = 0;

          if (this.totalRaffleEntries == 0) {
            this.finishedLoading = true;
          }

          this.raffles.forEach(raffle => {
              const inseratURI = raffle.inserat;
              axios.get(process.env.VUE_APP_API_URL + inseratURI)
                .then(inseratResponse => {
                  inseratResponse.data['raffleStatus'] = raffle.status;
                  inseratResponse.data['raffleId'] = raffle.id;
                  this.inserats.push(inseratResponse.data);
                }).finally(() => {
                    finishedLoadingEntriesCount++;
                    if (this.totalRaffleEntries === finishedLoadingEntriesCount) {
                      this.finishedLoading = true;
                    }
                });
          });

        });
  }
});

</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

</style>