
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonSpinner } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import inseratEntry from '../../components/inseratEntry.vue';
import { ticketOutline, ticketSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'ShowAll',
  data () {
    return {
      inserats: [],
      raffles: [],
      totalRaffleEntries: 0,
      finishedLoading: false,
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonSpinner,
    inseratEntry
  },
  created() {
    axios.get(process.env.VUE_APP_API_URL + '/inserat_raffle_entries?raffleUser.uuid=' + localStorage.userId + '&order[createdAt]=DESC')
        .then(response => {
          this.raffles = response.data["hydra:member"];
          this.totalRaffleEntries = response.data["hydra:totalItems"];
          let finishedLoadingEntriesCount = 0;

          if (this.totalRaffleEntries == 0) {
            this.finishedLoading = true;
          }

          this.raffles.forEach(raffle => {
              const inseratURI = raffle.inserat;
              axios.get(process.env.VUE_APP_API_URL + inseratURI)
                .then(inseratResponse => {
                  inseratResponse.data['raffleStatus'] = raffle.status;
                  inseratResponse.data['raffleId'] = raffle.id;
                  this.inserats.push(inseratResponse.data);
                }).finally(() => {
                    finishedLoadingEntriesCount++;
                    if (this.totalRaffleEntries === finishedLoadingEntriesCount) {
                      this.finishedLoading = true;
                    }
                });
          });

        });
  }
});

